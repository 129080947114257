import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="row">
          <div className="footer-col">
            <h4>FarmVille</h4>
            <ul>
              <li>
                <a href="/about">Story</a>
              </li>
              <li>
                <a href="/projects">Projects</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
              {/* <li>
                <a href="#">affiliate program</a>
              </li> */}
            </ul>
          </div>
          <div className="footer-col">
            <h4>Projects</h4>
            <ul>
              <li>
                <a href="/projects">the guest housess</a>
              </li>
              <li>
                <a href="/projects">farmville barouk</a>
              </li>
              <li>
                <a href="/projects">streeh guest house</a>
              </li>
              <li>
                <a href="/projects">rural products</a>
              </li>
              <li>
                <a href="/projects">the donkey farm</a>
              </li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Address</h4>
            <ul>
              <li>
                <i className="fa-solid fa-location-dot"></i>
                <a href="https://www.google.com/maps/place/Farmvillebarouk/@33.7071975,35.6648638,15z/data=!4m9!1m2!2m1!1s+Barouk,+El+Shouf+cezar!3m5!1s0x151f27108ab11faf:0x7d11594e43fc0300!8m2!3d33.7096875!4d35.6736875!15sChZCYXJvdWssIEVsIFNob3VmIGNlemFyWhciFWJhcm91ayBlbCBzaG91ZiBjZXphcpIBBGZhcm2aASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTjVhVjl5TFZKbkVBReABAA">Barouk, El Shouf</a>
              </li>
              <li>
                <i className="fa-solid fa-phone"></i>
                <a href="#">+961 76 711 811</a>
              </li>
              <li>
                <i className="fa-solid fa-envelope"></i>
                <a href="mailto:cezarsprojects@gmail.com">cezarsprojects@gmail.com</a>
              </li>
              {/* <li>
                <a href="#"></a>
              </li> */}
            </ul>
          </div>
          <div className="footer-col">
            <h4>follow us</h4>
            <div className="social-links">
              <a href="https://www.facebook.com/cezarprojects/">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#">
                <i className="fab fa-airbnb"></i>
              </a>
              <a href="#https://instagram.com/cezar_projects?igshid=YmMyMTA2M2Y=">
                <i className="fab fa-instagram"></i>
              </a>
              <a href="/">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <hr />
      <div>
        <p>All Right reserved by &copy;group5 B08 2022</p>
      </div> */}
    </footer>
  );
};

export default Footer;
