import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { useLocation } from "react-router-dom";
import Caro from "../../components/Carousel/Carousel";
import InstagramIcon from "@mui/icons-material/Instagram";
import Button from "@mui/material/Button";
import Airbnb from "../../components/Airbnb/Airbnb";
import CallIcon from "@mui/icons-material/Call";
import "./Project.css";

export default function Project() {
  const location = useLocation();
  let socialMedia = location.state.socialMedia;
  let instagram = "";
  let phone = "";
  for (let i = 0; i < socialMedia.length; i++) {
    if (socialMedia[i].idProject._id === location.state.project._id) {
      instagram = socialMedia[i].instagram;
      phone = socialMedia[i].whatsapp;
    }
  }

  return (
    <div className="project__page">
      {location.state.project.hasOwnProperty("airbnb") == true && (
        <Airbnb link={location.state.project.airbnb} />
      )}
      <Navbar />
      <div id="article_container">
        <div className="article_container_img">
          <Caro photos={location.state.project.photos} />
        </div>

        <div className="article_container_content">
          <div className="the">
            <div className="line" />
            <h4 className="h4-location">{location.state.project.location}</h4>
            <div className="line" />
          </div>
          <h1 className="project__name">{location.state.project.name}</h1>
          {/* <div className="contact__project"> */}
          <Button variant="inline" href={instagram}>
            <InstagramIcon style={{ fontSize: 50, color: "#58252b" }} />
          </Button>
          instagram
          <Button variant="inline" href={instagram}></Button>
          <CallIcon style={{ fontSize: 50, color: "#58252b" }} />
          {phone}
          <div className="divider" />
          <p className="description_project">
            {location.state.project.description}
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}
