import React from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/Home/Home";
import Projects from "./pages/Projects/Projects";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Reservation from "./pages/Reservation/Reservation";
import Project from "./pages/Project/Project";
import Whatsapp from "./components/Whatsapp/Whatsapp";
import Airbnb from "./components/Airbnb/Airbnb";
import Partners from "./pages/Partners/Partners";
import Featuredin from "./pages/Featuredin/Featuredin";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <Home /> <Whatsapp />
              </div>
            }
          />
          <Route
            exact
            path="projects"
            element={
              <div>
                <Projects /> <Whatsapp />
              </div>
            }
          />
          <Route
            exact
            path="project"
            element={
              <div>
                <Project /> <Whatsapp />
              </div>
            }
          />
          <Route
            exact
            path="about"
            element={
              <div>
                <About /> <Whatsapp />
              </div>
            }
          />
          <Route
            exact
            path="contact"
            element={
              <div>
                <Contact /> <Whatsapp />
              </div>
            }
          />
          <Route
            exact
            path="partners"
            element={
              <div>
                <Partners />
              </div>
            }
          />
          <Route
            exact
            path="featuredin"
            element={
              <div>
                <Featuredin />
              </div>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
