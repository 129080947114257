import React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import swiper1 from "../../media/images/cezarProject.png";
import swiper2 from "../../media/images/barouk4.jpg";
import swiper3 from "../../media/images/barouk.jpg";
// import Zoom from "@mui/material/Zoom";
import "./About.css";
const About = () => {
  return (
    <div>
      <Navbar />
      <div className="about-container">
        <div className="about-mission">
          <div className="mission-text">
            <h1 style={{ marginBottom: "40px" }}>Our Mission</h1>
            <p>
              To provide authentic rural experiences in the Shouf region, while
              simultaneously involving the local community, preserving cultural
              heritage, spreading awareness and building strong partnerships
              with different cooperatives to ensure sustainability.
            </p>
          </div>
          <div className="mission-image">
            <img src={swiper1} alt="mission-image1" />
          </div>
        </div>
        <div className="about-mission">
          <div className="mission-image">
            <img src={swiper3} alt="" className="swiper3-img" />
          </div>
          <div className="mission-text">
            <h1 style={{ marginBottom: "40px" }}>Our Vision</h1>
            <p>
              To be the leading project in promoting and implementing a genuine
              concept of rural development in Lebanon through innovative
              practices in alternative tourism, environmental awareness, and
              sustainable agriculture.
            </p>
          </div>
        </div>
        <div className="about-mission">
          <div className="mission-text">
            <h1 style={{ marginBottom: "40px" }}>Our Values</h1>
            <p>
              We as Cezar’s projects team has made the commitment to care about
              our people, our environment and our opportunities to live, work
              and prosper. Our visitors will definitely become part of the
              community. We work with different partners that have similar
              objectives to promote community conservation and sustainable
              tourism.
            </p>
          </div>
          <div className="mission-image">
            <img src={swiper2} alt="" className="swiper3-img" />
          </div>
        </div>
        <div className="about-content">
          <h1>How it all began</h1>
          <p>
            Cezar Projects was established in 2017, having since contributed
            immensely to the local community, creating many direct job
            opportunities, while also ensuring sustainable and prosperous
            economic and social development, in the long-term. Operating mainly
            in the Shouf region, Cezar Projects’ head office is located in
            Barouk, promoting sustainable rural tourism through a variety of
            products and services. The target audience includes those who seek
            to gain insight into the rural life, and immerse themselves in an
            “authentic” lifestyle, and allowing them to integrate themselves
            into the community by becoming a part of it. It all began with a
            simple idea from a simple individual. The idea was to offer a bed
            and breakfast in a homey guest house, operated and managed by Cezar
            Mahmoud and his family. Cezar, born and raised in Barouk El Shouf,
            graduated from Notre Dame University in Deir El Qamar with a B.A. in
            Advertising & Marketing, and went on to pursue a Master’s degree in
            NGO Management. While he conducted his studies, he also worked in
            environmental awareness in the Shouf Biosphere Reserve, working
            closely with tour guides, NGOs, educational institutes, and a
            diverse community of specialists and experts from all over the world
            who would come to the biosphere to experience its unique & rich
            environment for all kinds of purposes, whether it may be for leisure
            or education & research. This experience also contributed to Cezar’s
            awareness regarding the Shouf, its value and potential for the
            future. The Shouf is one of Lebanon’s greenest areas and is rich in
            cultural heritage. Visitors outside the Shouf are drawn to the
            region by its natural resources and opportunities to interact with
            locals and participate in cultural traditions. Cezar was inspired to
            begin his project after becoming more aware about the importance of
            rural development.
          </p>
          <p>
            This experience combined with Cezar’s extroverted personality,
            especially with his love for people in-general, creating
            experiences, making memories and ultimately enjoying the simple
            things in life, drove him to start Cezar Projects and start
            accommodating people from all sorts of backgrounds in his
            family-owned lodge in Barouk El Shouf. Despite it being up for-sale
            at the time, Cezar went ahead and listed the guesthouse on Airbnb,
            and to his surprise, it was booked not soon after.
          </p>
          <p>
            The first visitors were a group of tourists who came all the way
            from China. They had arrived at the guesthouse to stay for one night
            but ended up staying for the entire vacation since they enjoyed it
            so much. After that, everything was set in stone, and that’s how
            Cezar Projects came to be. The guesthouse continued to welcome more
            guests, both local and international, coming in from all over the
            world. The majority were families, schools, back-packers,
            corporations, and NGOs. The project’s success attributes to several
            factors, the most notable is its presence in the SBR. The Shouf
            Biosphere Reserve (SBR) makes up 24 villages with a total of 70,000
            residents.
          </p>
          <p>
            Living in a rural area and specifically in El Shouf where the
            healthy environment, clean water, green nature, and hospitable
            people, has been itself a great motive and opportunity for
            responsible youth to invest in rural tourism. And this is for sure
            because of the effective role of the Shouf Biosphere Reserve from
            years in protecting biodiversity and encouraging sustainable
            socio-economic development. Cezar’s projects came to complement and
            support the role and the objectives of the SBR. Human resource is
            another key element that is essential to the success of all Cezar
            Projects’ initiatives & programs. Supporting primarily local
            talents, providing them with all sorts of resources to excel in
            their careers. Up until today we have enabled many young individuals
            who have become part of the Cezar Projects team, working in various
            roles, such as project management, consultation, environmental
            research & awareness, communication & outreach, customer excellence,
            and more. The staff members are all from the community, and Cezar
            Projects’ host-guest relationship is considered a powerful factor
            for shaping tourists’ experiences and allowing them to form
            emotional bonds with rural communities.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;

// center a div?
