import { FaAirbnb } from "react-icons/fa";
import Fab from "@mui/material/Fab";
import "./airbnb.css";
export default function Airbnb(props) {
  return (
    <div className="floatingButtonWrap">
      <Fab color="white" aria-label="add">
        <a href={props.link}>
          <FaAirbnb fontSize="40" color="#FF5A5F" />
        </a>
      </Fab>
    </div>
  );
}
