import React from "react";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logoImg from "../../media/images/logoImg.png";
import logoName from "../../media/images/logoName.png";
import "./Navbar.css";

const Navbar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div className="header">
      <div className="logo">
        <img src={logoImg} className="logoImg" alt="logoImg" />
        <img src={logoName} className="logoName" alt="logoText" />
      </div>
      <div className="nav" ref={navRef}>
        <a href="/">Home</a>
        <a href="/projects">Projects</a>
        <a href="/about">Story</a>
        <a href="/Featuredin">Featured in</a>
        <a href="/partners">Partners</a>
        <a href="/contact">Contact</a>
        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <FaTimes />
        </button>
      </div>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </div>
  );
};

export default Navbar;
