import React from "react";
// import Slider from "../../components/Slider/Slider";
import Footer from "../../components/Footer/Footer";
import "./Home.css";
import Navbar from "../../components/Navbar/Navbar";

const Home = () => {
  // const ref = useRef(null);

  //const slides = document.querySelectorAll(re".slide");
  let slides = document.getElementsByClassName("slide");
  // const next = document.querySelector("#next");
  // const prev = document.querySelector("#prev");
  const auto = true; // Auto scroll
  const intervalTime = 7000;
  let slideInterval;

  const nextSlide = () => {
    // Get current class
    const current = document.querySelector(".current");
    // Remove current class
    current.classList.remove("current");
    // Check for next slide

    if (current.nextElementSibling) {
      // Add current to next sibling
      current.nextElementSibling.classList.add("current");
    } else {
      // Add current to start
      slides[0].classList.add("current");
    }
    setTimeout(() => current.classList.remove("current"));
  };

  const prevSlide = () => {
    // Get current class
    const current = document.querySelector(".current");
    // Remove current class
    current.classList.remove("current");
    // Check for prev slide
    if (current.previousElementSibling) {
      // Add current to prev sibling
      current.previousElementSibling.classList.add("current");
    } else {
      // Add current to last
      slides[slides.length - 1].classList.add("current");
    }
    setTimeout(() => current.classList.remove("current"));
  };

  function goNext(e) {
    nextSlide();
    if (auto) {
      clearInterval(slideInterval);
      slideInterval = setInterval(nextSlide, intervalTime);
    }
  }

  function goPrev(e) {
    prevSlide();
    if (auto) {
      clearInterval(slideInterval);
      slideInterval = setInterval(nextSlide, intervalTime);
    }
  }

  if (auto) {
    // Run next slide at interval time
    slideInterval = setInterval(nextSlide, intervalTime);
  }
  // }, [next, prev, auto]);

  // Auto slide

  return (
    <>
      <div className="home-navbar">
        <Navbar />
      </div>
      {/* <div>
        <Slider />
      </div> */}
      <div className="slider">
        <div className="slide current">
          <div className="content">
            <h1>Farmville Barouk</h1>
            <p>
              Farmville Barouk was the next step in the growth of Cezar
              Projects. Not only did come as a method to meet demand, but also
              has a very sentimental story behind it.
            </p>
            {/* <button className="content-btn">Explore</button> */}
          </div>
        </div>
        <div className="slide ">
          <div className="content">
            <h1>The Guesthouses</h1>
            <p>
              Cezar Project’s started off with the ‘’Main Guesthouse’’ being
              listed on Airbnb, and at the time, it was one of the few available
              guesthouses in the Shouf area.
            </p>
            <a href="projects">
              {/* <button
                className="content-btn"
                onClick={console.log("the button is clicked")}
              >
                Explore
              </button> */}
            </a>
          </div>
        </div>

        <div className="slide">
          <div className="content">
            <h1>Streeh Guesthouse</h1>
            <p>
              Through valuing the importance of part - nering with locals, Cezar
              Projects man - ages several projects, today. The first was the
              Streeh Guesthouse, located in a near - by village, Brih village.
            </p>
            {/* <button className="content-btn">Explore</button> */}
          </div>
        </div>
        <div className="slide">
          <div className="content">
            <h1>Rural Products</h1>
            <p>
              As a part of Cezar Projects mission aimed at empowering the rural
              community and contribute to rural development.
            </p>
            {/* <button className="content-btn">Explore</button> */}
          </div>
        </div>
        <div className="slide">
          <div className="content">
            <h1>Shouf Highland</h1>
            <p>
              Shouf Highland is a sunset bar with lodging services in the
              beautiful mountains of Ain Zhalta village near the Shouf Biosphere
              Reserve.
            </p>
            {/* <button className="content-btn">Explore</button> */}
          </div>
        </div>
        <div className="slide">
          <div className="content">
            <h1>The Donkey Farm</h1>
            <p>
              Each experience created by “Cezar Projects” has been unique, and
              another unique experience was or- ganized in the last year, this
              time a very authentic farming experience called “The Donkey Farm.”
            </p>
            {/* <button className="content-btn">Explore</button> */}
          </div>
        </div>
        {/* <div className="slide">
          <div className="content">
            <h1>Slide seven</h1>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sit hic
              maxime, voluptatibus labore doloremque vero!
            </p>
            <a href="/projects">
              <button className="content-btn">Explore</button>
            </a>
          </div>
        </div> */}
      </div>
      <div className="buttons">
        <button
          id="prev"
          onClick={(e) => {
            goPrev();
          }}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        <button
          id="next"
          onClick={(e) => {
            goNext();
          }}
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
