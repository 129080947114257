import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import NRDC from "../../media/images/NRDC.png";
import FRANCAIS from "../../media/images/FRANCAIS.jpeg";
import AMURT from "../../media/images/AMURT.png";
import "./partners.css";

export default function Partners() {
  return (
    <div>
      <Navbar />
      <div className="partners-container">
        <h1>Partners</h1>
        <div className="wrapper-partners">
          <div className="partner partner1">
            <img src={NRDC} alt="" />
            <h2>The NRDC Hub</h2>
            <div className="details-partner details2">
              <p>
                We try to be as inclusive and impactful as much as possible
                within the community, and in accordance with our core values and
                beliefs we teamed up with the NRDC to empower some wonderfully
                talented individuals that have “additional needs” to further
                incorporate them within society and specifically their own
                communities through providing them with a creative work space at
                Farmville Barouk in which they can show case their talents and
                teach visitors certain things such as how they make beaded
                bracelets, soaps, lotion scrubs, candles, and in some cases
                tables, and much more!
              </p>
            </div>
          </div>
          <div className="partner partner2">
            <img src={FRANCAIS} alt="" />
            <h2>Deir El Qamar</h2>
            <div className="details-partner details2">
              <p>
                Cezar Projects collaborated with the Institute Francais on
                various events and projects. Some of which include transforming
                a kiosk at Farmville Barouk into a hub. The hub allow the
                institute in creating a touch point in the village of Barouk and
                to its visiotrs, with the main purpose of providing books and
                posters from the main Institut Francais center’s library at Deir
                el Qamar. Cezar Projects has also partnered with Institut
                Francais on putting together several spectacular events.{" "}
              </p>
            </div>
          </div>
          <div className="partner partner3">
            <img src={AMURT} alt="" />
            <h2>
              Ananda Marga Universal Relief Team; Barouk Freidiss Municipality
            </h2>
            <div className="details-partner details3">
              <p>
                Recently we have launched a community kitchen at FarmVille
                Barouk, in partnership with AMURT Lebanon and Barouk-Freidiss
                Municipality.
                <br />
                The equipment were funded by KNH (Kindernothilfe, a German
                organization) in coordination with BMZ Germany.
                <br />
                This kitchen is opened for locals to come together and benefit
                from the advanced machines and cooking facilities in lower
                costs. In addition, this kitchen will be a great opportunity for
                learning, sharing experiences and developing skills.
                <br /> Also it will help improve food security and will
                effectively benefit from the agricultural resources in the
                region in order to enhance the production of the local rural
                products. Recently, we launched ‘’Kaki’’ Fruit brand in
                collaboration with Nour Halawi a nutritionist student
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
