import * as React from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Zoom from "@mui/material/Zoom";
import Loader from "../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import "./Projects.sass";

const Project = () => {
  let navigate = useNavigate();
  const [projects, setProjects] = React.useState([]);
  const [socialMedia, setSocialMedia] = React.useState([]);
  const fetchData = async () => {
    const response = await fetch("http://localhost:5000/api/project", {
      method: "GET",
    });
    if (!response) {
      throw new Error("Data coud not be fetched!");
    } else {
      return response.json();
    }
  };
  const fetchSocialMedia = async () => {
    const response = await fetch("http://localhost:5000/api/socialmedia", {
      method: "GET",
    });
    if (!response) {
      throw new Error("Data coud not be fetched!");
    } else {
      return response.json();
    }
  };
  const openProject = (project) => {
    navigate("/project", {
      state: {
        project: project,
        socialMedia: socialMedia,
      },
    });
  };
  React.useEffect(() => {
    fetchData()
      .then((res) => {
        setProjects(res);
      })
      .catch((e) => {
        console.log(e.message);
      });
    fetchSocialMedia()
      .then((res) => {
        setSocialMedia(res);
      })
      .catch((e) => {
        console.log(e.message);
      });
  }, []);
  if (projects.length === 0) {
    return <Loader />;
  }

  return (
    <div>
      <Navbar />

      <div className="container__project">
        {projects.map((project,index) => (
          <Zoom  key={index} in={true} style={{ transitionDelay: true ? `${index+8}00ms` : "0ms" }}>
            <div >
              <div
                className="card__project"
                onClick={(e) => openProject(project)}
             
              >
                <img src={project.photos[0]} />
                {/* <h3>{project.name}</h3> */}
                {/* <p>{project.location}</p> */}
              </div>
              <div className="title__project">
                <p className="title__text">{project.name}</p>
                <p className="location__text">{project.location}</p>
              </div>
            </div>
          </Zoom>
        ))}
      </div>

      {/* <Caro /> */}

      <Footer />
    </div>
  );
};

export default Project;
