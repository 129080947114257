import FloatingWhatsApp from "react-floating-whatsapp";
import LogoImg from "../../media/images/logoImg.png";

export default function Whatsapp() {
  return (
    <div>
      <FloatingWhatsApp
        avatar={LogoImg}
        phoneNumber="+96176711811"
        accountName="Cezar's Projects"
        chatMessage="Welcome to Cezar’s Projects for rural development. Please send your inquiry."
      />
    </div>
  );
}
