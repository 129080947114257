import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./Featuredin.css";
import alnahar from "../../media/images/alnahar.jpg";
export default function Featuredin() {
  return (
    <div>
      <Navbar />
      <div className="Featuredin">
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Responsive Features Section</title>
        {/* Font Awesome CDN */}
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
        />
        {/* Google Fonts */}
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap"
          rel="stylesheet"
        />
        {/* Stylesheet */}
        <link rel="stylesheet" href="style.css" />
        <section>
          <div className="row">
            <h1>Featured in</h1>
          </div>
          <div className="row">
            {/* Column One */}
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={"https://www.annahar.com/images/favicon.png"}
                  ></img>
                </div>
                <h3>Al Nahar</h3>
              </div>
            </div>
            {/* Column Two */}
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={"https://al-akhbar.com/Images/quotes-open-red.png"}
                  ></img>
                </div>
                <h3>Al Akhbar</h3>
              </div>
            </div>
            {/* Column Three */}
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={"https://kukbuk.pl/apple-touch-icon.png"}
                  ></img>
                </div>
                <h3>Kukbuk</h3>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={
                      "https://desktop.beiruting.com/Content/images/favicon.png"
                    }
                  ></img>
                </div>
                <h3>Beiruting</h3>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={
                      "https://www.beirut.com/images/favicons/apple-icon-57x57.png"
                    }
                  ></img>
                </div>
                <h3>Beirut.com</h3>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={"https://d25b3ngygxsbuv.cloudfront.net/489b7471-c36a-4d97-86cd-beb27ab1a71a.png"}
                  ></img>
                </div>
                <h3>Hakawati.com</h3>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={"https://migrationology.com/wp-content/themes/migrationology/library/images/favicon.ico"}
                  ></img>
                </div>
                <h3>Migrationology</h3>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={"https://www.mtv.com.lb/Content/images/favicons/favicon-32x32.png"}
                  ></img>
                </div>
                <h3>MTV alive</h3>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={"https://www.the961.com/wp-content/uploads/2021/10/cropped-961-logo-SM-32x32.png"}
                  ></img>
                </div>
                <h3>The 961</h3>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={"https://www.lebanontimes.news/wp-content/uploads/2019/03/LEBANON-TIMES-LOGO-copy-1.png"}
                  ></img>
                </div>
                <h3>Lebanon Times</h3>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={"https://s.lorientlejour.com/assets/images/apple-icon-57x57.png"}
                  ></img>
                </div>
                <h3>L’orient le Jour</h3>
              </div>
            </div>
            <div className="column">
              <div className="card">
                <div className="icon">
                  <img
                    className="imageF"
                    src={"https://www.hospitalitynewsmag.com/wp-content/themes/magellan-child/assets/img/favico.png"}
                  ></img>
                </div>
                <h3> Hospitality News</h3>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
