import * as React from "react";
import "./Contact.css";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const Contact = () => {
  const [email, setEmail] = React.useState();
  const [text, setText] = React.useState();
  const [fullName, setFullName] = React.useState();

  const contactUs = async () => {
    console.log(email, text, fullName);
    const response = await fetch("http://localhost:5000/api/contact", {
      method: "POST",
      body: new URLSearchParams({
        email: email,
        text: text,
        fullName: "fullName",
      }),
      headers: {
        "Content-type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Connection: "keep-alive",
        "Accept-Encoding": "gzip, deflate, br",
      },
    });
    if (!response) {
      throw new Error("Data coud not be fetched!");
    } else {
      return response.json();
    }
  };
  const submit = () => {
    contactUs()
      .then((res) => {
        console.log(res);
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  return (
    <div className="contact-page">
      <Navbar />
      <div className="contact-container">
        {/* Contact Container  */}

        <div className="form-container">
          <h3>Contact Us</h3>
          <form className="contact-form">
            <label>Full Name</label>
            <input
              type="text"
              placeholder="Your Name"
              required
              onChange={(e) => setFullName(e.target.value)}
            />
            <label>Email Address</label>
            <input
              type="email"
              placeholder="Enter Your Email ..."
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Message</label>
            <textarea
              type="text"
              cols="30"
              rows="10"
              placeholder="Write a message here"
              required
              onChange={(e) => setText(e.target.value)}
            />

            <button onClick={submit} className="send-button">
              Send Message
            </button>
          </form>
        </div>

        {/* Map */}
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.9714569744815!2d35.67149881564978!3d33.70968748070056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f27108ab11faf%3A0x7d11594e43fc0300!2sFarmvillebarouk!5e0!3m2!1sen!2slb!4v1662903524637!5m2!1sen!2slb"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
