import "./Loader.css";

export default function Loader() {
  return (
    
      <div >
          <div className="hypnotic-1"></div>
          <div className="hypnotic-2"></div>
      </div>
      
    
  );
}
